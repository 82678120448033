// Uncomment for testing
//initBookingOffCanvas();

$(document).ready(function () {
	// $('.header__book button').on('click', initBookingOffCanvas);
});


function initBookingOffCanvas() {
	$('#booking').show().foundation('open');
	
	// Avoid setting up everything twice if the booking panel has already been opened
	if ($('body').hasClass('booking-init')) {
		return;
	}
	$('body').addClass('booking-init');


	$('.booking__form select').selectric();


	$('.check-in').on('click', function () {
		$('.booking__calendar--arrive').slideToggle();
		$('.booking__calendar--depart').slideUp();
	});

	$('.check-out').on('click', function () {
		$('.booking__calendar--depart').slideToggle();
		$('.booking__calendar--arrive').slideUp();
	});





	var bookingContainer = $('.booking__form');
	var monthNames = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
	var dayNames   = [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ];
	var nowTemp = new Date();
	var tomorrow = new Date();
		tomorrow.setDate(nowTemp.getDate() + 1);

	var $checkInField = bookingContainer.find('.check-in');
	var $checkOutField = bookingContainer.find('.check-out');

	$checkInField.on('pick.datepicker', function (e) {
		var checkInDate  = $checkInField.datepicker('getDate');
		var checkOutDate = $checkOutField.datepicker('getDate');
		var dayAfterCheckIn = new Date(checkInDate);
			dayAfterCheckIn.setDate(checkInDate.getDate() + 1);

		// Ensure checkout comes after checkin.
		// Otherwise it all gets a bit Restaurant at the End of the Universe-ey.
		if ( checkInDate >= checkOutDate ) {
			$checkOutField.datepicker('setDate', dayAfterCheckIn);
		}

		$checkOutField.datepicker('setStartDate', dayAfterCheckIn);

		if (e.view == 'day') {
			$('.booking__calendar--arrive').slideUp();
			$('.booking__calendar--depart').slideDown();
		}
	});

	$checkOutField.on('pick.datepicker', function (e) {
		if (e.view == 'day') {
			$('.booking__calendar--depart').slideUp();
		}
	});
	
	$.fn.datepicker.languages['pt-BR'] = {
		format: 'dd/mm/yyyy',
		days: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
		daysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
		daysMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
		months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
		monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
	};
	
	var bookingWidgetLanguage = 'en-US';
	
	if (window.location.href.indexOf('/pt/') > -1) {
		bookingWidgetLanguage = 'pt-BR';
		$('input[name="locale"]').val('pt-PT');
	}

	var checkin = $checkInField.datepicker({
		language: bookingWidgetLanguage,
		format: 'dd/mm/yyyy',
		//autoPick: true,
		inline: true,
		container: '.booking__calendar--arrive',
		date: null,
		startDate: nowTemp,
		pick: function (e) {
			// Formatted date for the booking engine
			$('input[name="arrivalDate"]').val( pad( e.date.getMonth() + 1*1 , 2) + '-' + pad( e.date.getDate() , 2) + '-' + e.date.getFullYear() );
		}
	});

	var checkout = $checkOutField.datepicker({
		language: bookingWidgetLanguage,
		format: 'dd/mm/yyyy',
		//autoPick: true,
		inline: true,
		container: '.booking__calendar--depart',
		date: null,
		startDate: tomorrow,
		pick: function (e) {
			// Formatted date for the booking engine
			$('input[name="departureDate"]').val( pad( e.date.getMonth() + 1*1 , 2) + '-' + pad( e.date.getDate() , 2) + '-' + e.date.getFullYear() );
		}
	});
}
