$(document).foundation();

$(document).ready(function () {
    // utils
    initVerticalCenter();
    initAnimateInView();
    inlineSVGs([
	    '.header__saved img',
		'.save__button img',
		'.footer__social img'
    ]);

    // global
    initHeader();
    initSliders();
    initVideoPopups();
    initPopup();
	initForms();
	initOffCanvas();
	initBackToTop();
	initAccordions();
	initLangDropdown();

    // template specific
    initProperties();
	initDetails();
	initAttractionsMap();
	initImageBlocks();
	initTabbedGrid();
	initDetailGallery();
	initGalleryPage();
	initDatepicker();
	initRecaptcha();
});


function initRecaptcha() {
	$('form').not('.properties__filter, .booking__form, .newsletter__form').each(function () {
        $(this).append('<div class="g-recaptcha" data-sitekey="6Lc-p4IbAAAAADrdTleK3ikgPv0PvqFi8IqYYa-R"></div>');
    });
}


function initDatepicker() {
	// Events form:
	var now = new Date();
	$('[data-toggle="datepicker"]').datepicker({
		format: 'dd/mm/yy ',
        autoPick: true,
        autoHide: true,
        date: null,
        startDate: now
	});



	// Temp booking request form:
	var nowTemp = new Date();
	var tomorrow = new Date();
		tomorrow.setDate(nowTemp.getDate() + 1);

	var $checkInField = $('.form').find('input[name="arrival"]');
	var $checkOutField = $('.form').find('input[name="departure"]');

	$checkInField.on('hide.datepicker', function () {
		var checkInDate  = $checkInField.datepicker('getDate');
		var checkOutDate = $checkOutField.datepicker('getDate');
		var dayAfterCheckIn = new Date(checkInDate);
			dayAfterCheckIn.setDate(checkInDate.getDate() + 1);

		// Ensure checkout comes after checkin.
		// Otherwise it all gets a bit Restaurant at the End of the Universe-ey.
		if ( checkInDate >= checkOutDate ) {
			$checkOutField.datepicker('setDate', dayAfterCheckIn);
		}

		$checkOutField.datepicker('setStartDate', dayAfterCheckIn);

		$checkOutField[0].focus();
	});

	var checkin = $checkInField.datepicker({
		format: 'dd/mm/yyyy',
		autoPick: false,
		autoHide: true,
		date: null,
		startDate: nowTemp
	});

	var checkout = $checkOutField.datepicker({
		format: 'dd/mm/yyyy',
		autoPick: false,
		autoHide: true,
		date: null,
		startDate: tomorrow
	});
}


function initVerticalCenter() {
    $('.vertical-center').each(function () {
        $(this).wrapInner('<div class="vertical-center-table"><div class="vertical-center-row"><div class="vertical-center-cell"></div></div></div>');
    });
}



// https://stackoverflow.com/questions/24933430/img-src-svg-changing-the-fill-color
function inlineSVGs(inputValue) {
    var selectorString = '';
    if (typeof inputValue === "object") {
        selectorString = inputValue.join(',');
    } else {
        selectorString = inputValue;
    }

    $(selectorString).each(function () {
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        if (imgURL.indexOf('.svg') > -1) {
	        $.get(imgURL, function (data) {
	            // Get the SVG tag, ignore the rest
	            var $svg = $(data).find('svg');

	            // Add replaced image's ID to the new SVG
	            if (typeof imgID !== 'undefined') {
	                $svg = $svg.attr('id', imgID);
	            }
	            // Add replaced image's classes to the new SVG
	            if (typeof imgClass !== 'undefined') {
	                $svg = $svg.attr('class', imgClass + ' replaced-svg');
	            }

	            // Remove any invalid XML tags as per http://validator.w3.org
	            $svg = $svg.removeAttr('xmlns:a');

	            // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
	            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
	                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
	            }

	            // Replace image with new SVG
	            $img.replaceWith($svg);
	        }, 'xml');
        }
    });
}



function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}



/**
 * Scales any element to simulate the CSS background-size: cover property.
 * @param {DOMelement} element The element to scale
 * @param {bool} [scaleToParent] Scale the element to fill the parent instead
 *                               of the whole browser window.
 */
function coverViewport(element, scaleToParent) {
    if (typeof scaleToParent === 'undefined') { scaleToParent = false; }

    var viewportWidth, viewportHeight;
    if (scaleToParent) {
        viewportWidth = $(element).parent().innerWidth();
        viewportHeight = $(element).parent().innerHeight();
    } else {
        viewportWidth = $(window).innerWidth();
        viewportHeight = $(window).innerHeight();
    }

    var $element = $(element);

    var elementWidth = $element.attr('width');
    var elementHeight = $element.attr('height');

    // Aspect ratio = height / width
    var viewportRatio = viewportHeight / viewportWidth;
    var videoRatio = elementHeight / elementWidth;

    //console.log('Viewport: ', viewportRatio, ' Video: ', videoRatio);


    var newWidth = (elementWidth / elementHeight) * viewportHeight
    var newHeight = (elementHeight / elementWidth) * viewportWidth;
    var newTop = 0;

    if (viewportRatio > videoRatio) {
        // set video height to the viewport, and extend the video beyond the viewport width, i.e. portrait viewport
        //console.log('crop left and right');
        $element.css('height', viewportHeight);
        $element.css('width', newWidth);
        $element.css('left', '-' + (newWidth - viewportWidth) / 2 + 'px');
        $element.css('top', '0');
    } else if (viewportRatio < videoRatio) {
        // set video width to the viewport, and extend the video beyond the viewport height, i.e. landscape viewport
        //console.log('crop top and bottom');
        $element.css('width', viewportWidth);
        $element.css('height', newHeight);
        $element.css('left', '0');
        $element.css('top', '-' + (newHeight - viewportHeight) / 2 + 'px');
    } else {
        // set the video width AND height to the viewport size, i.e. viewport is the same ratio as the video
        //console.log('no crop');
        $element.css('height', viewportHeight);
        $element.css('width', viewportWidth);
        $element.css('left', '0');
        $element.css('top', '0');
    }
}



function initAnimateInView() {
	$(window).on('DOMContentLoaded load resize scroll', animateElementsInView);
	animateElementsInView();
}

function animateElementsInView() {
	$('[data-animate-in-view]').each(function () {
		//console.log($(this));
		if ( isElementInViewport($(this)) ) {
			$(this).addClass( $(this).data('animate-in-view') );
		}
	});
}

function isElementInViewport (el) {
	var rect = el[0].getBoundingClientRect();
	//var midPoint = rect.top + (rect.height / 2);
	var triggerPoint = rect.top + 50;
	
	
	if ( 
		( triggerPoint >= 0 ) &&
		( triggerPoint <= window.innerHeight )
	) {
		return true;
	} else {
		return false;
	}
	
	/*if (typeof jQuery === "function" && el instanceof jQuery) {
		el = el[0];
	}

	var rect = el.getBoundingClientRect();

	var t = rect.y;
	var b = window.innerHeight - rect.y - rect.height;
	var h = window.innerHeight;

	if (
		( (t >= 0) && (t <= h) ) ||
		( (b >= 0) && (b <= h) )
	) {
		return true;
	} else {
		return false;
	}*/
}







function initHeader() {

	function throttle(fn, threshhold, scope) {
	  threshhold || (threshhold = 250);
	  var last,
	      deferTimer;
	  return function () {
	    var context = scope || this;

	    var now = +new Date,
	        args = arguments;
	    if (last && now < last + threshhold) {
	      // hold on to it
	      clearTimeout(deferTimer);
	      deferTimer = setTimeout(function () {
	        last = now;
	        fn.apply(context, args);
	      }, threshhold);
	    } else {
	      last = now;
	      fn.apply(context, args);
	    }
	  };
	}

	// Detect if the header is over and image slider, if yes then give it a transparent background
	setHeaderStyle();
	$(window).on('scroll', setHeaderStyle);
}



function setHeaderStyle() {
	if (window.pageYOffset < 150) {
		$('.header').removeClass('header--compact');
	} else {
		$('.header').addClass('header--compact');
	}
}




function initSliders() {
	$('.slider__inner').reformSlider({
		speed: 4000,
		animationSpeed: 1000,
		arrows: false,
		pagination: $('.slider').hasClass('slider--has-dots'),
		paginationArrows: false
	});

	if ( $('.slider__slide iframe').length > 0 ) {
		$('.slider').addClass('slider--has-video');
		coverViewport('.slider__slide iframe', true);
		$(window).on('resize', function () {
			coverViewport('.slider__slide iframe', true);
		});
	}


	$('.slider__scroll button').on('click', function () {
		var scrollBy = 0;

		if ( ($('.slider').hasClass('slider--parallax')) ) {
			if ( window.pageYOffset < 50 ) {
				scrollBy = $('.slider').outerHeight() - window.innerHeight + $('.header').outerHeight();

				if ( (window.innerWidth < 640) && (scrollBy > 50) ) {
					scrollBy = 100;
				}
			} else {
				scrollBy = $('.slider, .location').outerHeight() - $('.header').outerHeight();
			}
		} else {
			scrollBy = $('.slider, .location').outerHeight() - $('.header').outerHeight();
		}


		$('html, body').animate({
			scrollTop: scrollBy
		}, 1000);

		return false;
	});

	var sliderWindowDelta = 0;

	if ( $('.slider').hasClass('slider--parallax') ) {
		trackSliderParallax();
		$(document).on('scroll', trackSliderParallax);

		sliderWindowDelta = $('.slider').outerHeight() - window.innerHeight;
		$(window).on('resize', function () {
			sliderWindowDelta = $('.slider').outerHeight() - window.innerHeight;
			trackSliderParallax();
		});
	}

	function trackSliderParallax() {
		// Nicely fade in the overlay content
		if ( window.pageYOffset > 10 ) {
			$('.slider__floating-content').addClass('visible');
		} else {
			$('.slider__floating-content').removeClass('visible');
		}


		// unstick the scroll button once we're past the slider
		if ( window.pageYOffset > ($('.slider').outerHeight() - window.innerHeight + $('.header').outerHeight() ) ) {
			$('.slider').removeClass('slider--parallax-is-fixed');
		} else {
			$('.slider').addClass('slider--parallax-is-fixed');
		}


		// The meat of the parallax effect: slide up the content as the page scrolls down
		var moveBy = window.pageYOffset;
		if ( moveBy > sliderWindowDelta ) {
			moveBy = sliderWindowDelta;
		}
		$('.slider__floating-content').css('transform', 'translateY(-' + (moveBy) + 'px)'); // Move up the overlay text...
		$('.slider__inner').css('transform', 'translateY(' + (moveBy / 2) + 'px)'); // ...and at the same time slow down the slider scrolling.
	}



	$('.explore__slider').slick({
		fade: true,
		arrows: false,
		dots: true
	});


	$('.small-carousel__slider, .large-carousel__slider').each( function () {
		var maxSlidesToShowDesktop = 3;
		var maxSlidesToShowMedium = 2;
		var useCenterMode = false;

		if ( $(this).find('.small-carousel__item, .large-carousel__item').length < 2 ) {
			maxSlidesToShowDesktop = 1;
			maxSlidesToShowMedium = 1;
			useCenterMode = true;
			$(this).addClass('one-image');
		} else if ( $(this).find('.small-carousel__item, .large-carousel__item').length < 3 ) {
			maxSlidesToShowDesktop = 2;
			useCenterMode = true;
		}
		
		$(this).on('init', function (event, slick) {
			$(window).trigger('resize');
		});

		$(this).slick({
			slidesToShow: maxSlidesToShowDesktop,
			centerMode: useCenterMode,
			centerPadding: 0,
			arrows: true,
			dots: true,
			infinite: false,
			prevArrow: '<span class="slick-prev"></span>', 
			nextArrow: '<span class="slick-next"></span>', 
			appendArrows: $(this).next('.carousel-nav'), 
			appendDots: $(this).next('.carousel-nav'),
			responsive: [
				{
					breakpoint: 800,
					settings: {
						slidesToShow: maxSlidesToShowMedium
					}
				},
				{
					breakpoint: 640,
					settings: {
						slidesToShow: 1
					}
				}
			]
		});
	});
	
	
	$('.gallery-carousel__inner').slick({
		fade: true,
		arrows: true,
		dots: true
	});
}







function initVideoPopups() {
	var $modal;
	var modal = '<div id="video-modal" class="gallery-modal gallery-modal--video full reveal" data-reveal><button class="gallery-modal__close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><div class="gallery-modal__iframe"><iframe frameborder="0" src="{{iframeSrc}}"></iframe></div></div>';

	// Detect video links and embed them in a popup
	$('a[href*="youtu"], a[href*="vimeo.com"]').not('[href*="youtube.com/channel"]').addClass('is-video').on('click', function () {
		$modal = $( modal.replace('{{iframeSrc}}', generateVideoEmbedUrl($(this).attr('href'))) );

		$('body').append($modal);

		$('#video-modal').foundation();
        $modal.foundation('open');

        $modal.on('closed.zf.reveal', function () {
	        $modal.remove();
        });

		return false;
	});
}

function generateVideoEmbedUrl(videoUrl) {
	if (videoUrl.indexOf('youtube.com/embed') > -1) {
		// already a YouTube embed code, return same link
		return videoUrl;
	} else if (videoUrl.indexOf('youtu') > -1) {
		// must be either a full or shortened YouTube url so parse and create embed link
	    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
	    var match = videoUrl.match(regExp);

	    if (match && match[2].length == 11) {
	        return 'https://www.youtube.com/embed/' + match[2] + '?rel=0&autoplay=1&modestbranding=1';
	    } else {
	        return videoUrl;
	    }
	} else if (videoUrl.indexOf('player.vimeo.com') > -1) {
		// already a Vimeo embed code, return same link
		return videoUrl;
	} else if (videoUrl.indexOf('https://vimeo.com/') > -1) {
		// normal Vimeo link, so generate and embed code
		return 'https://player.vimeo.com/video/' + videoUrl.split('/')[3] + '?autoplay=true&byline=false&portrait=false&title=false';
	}

	// if all else fails, return the original link
	return embedUrl;
}












var customMapStyle=[{featureType:"administrative",elementType:"labels.text.fill",stylers:[{color:"#444444"}]},{featureType:"landscape",elementType:"all",stylers:[{color:"#d8e4e3"},{lightness:"26"}]},{featureType:"poi",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"road",elementType:"all",stylers:[{saturation:-100},{lightness:45}]},{featureType:"road.highway",elementType:"all",stylers:[{visibility:"simplified"}]},{featureType:"road.arterial",elementType:"labels.icon",stylers:[{visibility:"off"}]},{featureType:"transit",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"water",elementType:"all",stylers:[{color:"#849cba"},{visibility:"on"},{gamma:"3.38"},{lightness:"-21"}]}];


function initAttractionsMap() {
	$('[data-attractions-map]').each(function () {
		var map, centerLat, centerLng, zoom, marker, markerLat, markerLng;

		if (typeof $(this).data('map-center-coords') != 'undefined') {
			centerLat = parseFloat( $(this).data('map-center-coords').split(',')[0] );
			centerLng = parseFloat( $(this).data('map-center-coords').split(',')[1] );
		} else {
			centerLat = 0;
			centerLng = 0;
		}

		if ( $(window).innerWidth() <= 640 ) {
			zoom = $(this).data('map-zoom-small') || 8;
		} else {
			zoom = $(this).data('map-zoom') || 8;
		}

		if (typeof customMapStyle !== 'undefined') {
			map = new google.maps.Map($(this)[0], {
				center: {lat: centerLat, lng: centerLng},
				zoom: zoom,
				zoomControl: true,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				styles: customMapStyle
			});
		} else {
			map = new google.maps.Map($(this)[0], {
				center: {lat: centerLat, lng: centerLng},
				zoom: zoom,
				zoomControl: true,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false
			});
		}

		$(this).siblings('[data-map-marker]').each(function () {
			if (typeof $(this).data('map-marker-coords') != 'undefined') {
				markerLat = parseFloat( $(this).data('map-marker-coords').split(',')[0] );
				markerLng = parseFloat( $(this).data('map-marker-coords').split(',')[1] );
			} else {
				markerLat = 0;
				markerLng = 0;
			}

			marker = new google.maps.Marker({
				position: {lat: markerLat, lng: markerLng},
				map: map
			});

			if (typeof $(this).data('map-marker-url') != 'undefined') {
				if ((typeof $(this).data('map-marker-width') != 'undefined') && (typeof $(this).data('map-marker-height') != 'undefined')) {
					marker.setIcon({
						url: $(this).data('map-marker-url'),
						size: new google.maps.Size($(this).data('map-marker-width'), $(this).data('map-marker-height')),
						origin: new google.maps.Point(0, 0),
						anchor: new google.maps.Point(parseInt($(this).data('map-marker-width') / 2, 10), $(this).data('map-marker-height'))
					});
				} else {
					marker.setIcon($(this).data('map-marker-url'));
				}
			}
		});
	});
}




function initPopup() {
    if ($('#popup').length > 0) {
        var popupDelay = $('#popup').data('popup-delay') || 0;
        var popupKeepHiddenFor = $('#popup').data('popup-keep-hidden-for-n-days') || 0;

        if (!Cookies.get('hasSeenPopup')) {
            setTimeout(function() {
                $('#popup').foundation('open');

                if (popupKeepHiddenFor > 0) {
                    Cookies.set('hasSeenPopup', 'true', { expires: popupKeepHiddenFor });
                }
            }, popupDelay);
        }
    }
}


function initForms() {
	$('.validate-this').validate();

	$('.form select').selectric();
}




function initOffCanvas() {
	$('.off-canvas__primary > ul > li').each(function () {
		// Automatically add the "Overview" links to the landing page
		if ( $(this).find('ul').length > 0 ) {
			var overviewLink = $(this).find('a').first().attr('href');
			$(this).find('ul').prepend('<li><a href="' + overviewLink + '">' + $(this).find('a').first().text() + ' Overview</a></li>');
		}
	});


	$('.off-canvas__primary > ul > li > a').on('click', function () {
		if ( $(this).next('.off-canvas__primary-dropdown').length > 0 ) { // only trigger if there are sub pages to show
			if ( $(this).parent().hasClass('open') ) {
				// second click of main item allows clicking directly through
				return true;
			} else {
				// close any other open items
				$('.off-canvas__primary > ul > li.open').removeClass('open').find('.off-canvas__primary-dropdown').slideUp();

				// and open the new one
				$(this).parent().addClass('open');
				$(this).next().slideDown();
			}
			return false; // prevent navigating
		}
	});
}


function initProperties() {

}



function initBackToTop() {
	$('.back-to-top button').on('click', function () {
		$('html, body').animate({
			scrollTop: 0
		}, 1000);

		return false;
	});
}


function initDetails() {
	$('.details__toggle').on('click', function () {
		if ( $('.details__content-column-2').is(':visible') ) {
			$('.details__content-column-2').slideUp();
			$(this).text( $(this).data('more-text') );
		} else {
			$('.details__content-column-2').slideDown();
			$(this).text( $(this).data('less-text') );
		}
	});
}


function initAccordions() {
	$('.accordion').accordiom({
		showFirstItem: false
	});
}


function initImageBlocks() {
	$('.image-blocks__content-inner').each(function () {
		var contentHeight = 0;

		$(this).children().each(function () {
			contentHeight += $(this).outerHeight();
		});

		var $heading = $(this).find('h2');

		// Move the heading of each box down by half of the content height
		$heading.css('transform', 'translateY(' + contentHeight/2 + 'px)');

		// Add the transition style AFTER it's moved to avoid a weird droopy page load
		setTimeout(function () {
			$heading.css('transition', 'all 0.7s ease-in-out');
		}, 300);
	});
}


function initTabbedGrid() {
	var tabDropdownList = '';
	$('.tabbed-grid__tabs > li > a').each(function () {
		tabDropdownList += '<option value="' + $(this).attr('href') + '">' + $(this).text() + '</option>';
	});

	$('.tabbed-grid__dropdown').append('<select>' + tabDropdownList + '</select>');

	$('.tabbed-grid__dropdown select').selectric({
		onChange: function(element) {
			$('.tabbed-grid__tabs a[href="' + $(element).val() + '"]').trigger('click');
		}
	});

	$('.tabbed-grid__more').accordiom({
		showFirstItem: false,
		buttonBelowContent: true,
		beforeChange: function () {
			if ( $(this).hasClass('on') ) {
				$(this).text( $(this).data('more-text') );
			} else {
				$(this).text( $(this).data('less-text') );
			}
		}
	});
}


function initDetailGallery() {

	$('[data-gallery]').on('click', function () {
		// var activeSlideIndex = $(this).data('slide-index') || $(this).next().find('.slick-active').index();

		var activeSlideIndex = $(this).index() || 0;

		$('.gallery-popup__inner')
		.empty()
		.load($(this).data('gallery'), function () {
			$('.gallery-popup__slider').slick({
				initialSlide: Math.max(activeSlideIndex -1, 0),
				arrows: true,
				infinite: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				centerMode: true,
				centerPadding: '200px',
				responsive: [
					{
						breakpoint: 640,
						settings: {
							centerMode: true,
							centerPadding: '0px',
							slidesToShow: 1,
							slidesToScroll: 1,
							centerMode: true
						}
					}
				]
			});
		});


		$('.gallery-popup').on('closed.zf.reveal', function () {
			$('body').removeClass('is-reveal-open');
		});

		$('.gallery-popup').foundation('open');
	});
}


function initGalleryPage() {
	$('.gallery .gallery-popup__slider').slick({
		arrows: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: true,
		centerPadding: '200px',
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					centerMode: true,
					centerPadding: '0px',
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: true
				}
			}
		]
	});
}


function initLangDropdown() {
	$('.header__lang button').on('click', function(e) {
		e.stopPropagation();
		
		$('.header__lang ul').toggleClass('open');
	});
	
	$('body').on('click', function() {
		$('.header__lang ul').removeClass('open');
	});
}
