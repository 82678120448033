/*
 * Filter Properties
 * Based on Filter Anything:
 * https://github.com/80DAYS/Filter-Anything
 */
$(document).ready(function() {
    // Fix for the timing of the new animations
    setTimeout(function () {
        $('.properties .transform--will-reveal').css('mask-image', 'none');
    }, 1500);
    
    $('[data-filter-properties]').each(function() {
        var $_filterElement = $(this);
        var $_filterItems = $_filterElement.find('.properties__item');
        
        // Clear all the selected options and refresh the filers
        $('.properties__filter-all').on('click', function (e) {
            e.preventDefault();
            $('input[name="property-type"]').prop('checked', false);
            $('input[name="property-bedrooms"]').prop('checked', false);
            $('input[name="property-sleeps"]').prop('checked', false);
            $('input[name="property-features"]').prop('checked', false);
            $('.properties__filter input').first().trigger('change');
        });


        $('.properties__filter input').on('change', function() {
            // Update the button colours if any number of filters have been selected
            if ( 
                ($('input[name="property-type"]:checked').length > 0) ||
                ($('input[name="property-bedrooms"]:checked').length > 0) ||
                ($('input[name="property-sleeps"]:checked').length > 0) ||
                ($('input[name="property-features"]:checked').length > 0)
            ) {
                $('.properties__filter-all').removeClass('on');
            }  else {
                $('.properties__filter-all').addClass('on');
            }
            
            
            if ($('input[name="property-type"]:checked').length > 0) {
                $('.properties__filter-column--property-type h3').addClass('on');
            } else {
                $('.properties__filter-column--property-type h3').removeClass('on');
            }
            
            
            if ($('input[name="property-bedrooms"]:checked').length > 0) {
                $('.properties__filter-column--property-bedrooms h3').addClass('on');
            } else {
                $('.properties__filter-column--property-bedrooms h3').removeClass('on');
            }
            
            
            if ($('input[name="property-sleeps"]:checked').length > 0) {
                $('.properties__filter-column--property-sleeps h3').addClass('on');
            } else {
                $('.properties__filter-column--property-sleeps h3').removeClass('on');
            }
            
            
            if ($('input[name="property-features"]:checked').length > 0) {
                $('.properties__filter-column--property-features h3').addClass('on');
            } else {
                $('.properties__filter-column--property-features h3').removeClass('on');
            }
            
            
            
            // Now handle the actual filtering
            
            var propertyType = $('input[name="property-type"]:checked').val();
            var bedrooms = $('input[name="property-bedrooms"]:checked').val();
            var sleeps = $('input[name="property-sleeps"]:checked').val();
            
            var features = [];
            $('input[name="property-features"]:checked').each(function () {
                features.push( $(this).val().replace('features-', '') );
            });
            
            // Only show the items that match the filter
            $_filterItems.each(function() {
                // Assume in the first instance that the property should be visible
                var shouldShow = true;
                
                // Find reasons to hide this item if it doesn't match the selected filters
                if ( typeof propertyType !== 'undefined' ) {
                    if ( $(this).data('type') !== propertyType ) { // check for exact match of property type
                        shouldShow = false;
                    }
                }
                
                if ( typeof bedrooms !== 'undefined' ) {
                    if ( $(this).data('bedrooms') != bedrooms ) { // find properties that match the chosen number of bedrooms
                        shouldShow = false;
                    }
                }
                
                if ( typeof sleeps !== 'undefined' ) {
                    if ( $(this).data('sleeps') < sleeps ) { // find properties that sleep *at least* the selected number
                        shouldShow = false;
                    }
                }
                
                if ( features.length > 0 ) {
                    // check that all of the selected features are in the property
                    for (var i = 0; i < features.length; i++) {
                        //console.log($(this).data('features').indexOf(features[i]));
                        if ( $(this).data('features').indexOf(features[i]) === -1 ) {
                            shouldShow = false;
                        }
                    }
                }
                
                if ( shouldShow ) {
                    $(this).fadeIn();
                } else {
                    $(this).fadeOut();
                }
            });
            
            
            // Count the remaining items and decide whether or not to show the "no results" message, and also if any groups need to be hidden
            setTimeout( function () {
                if ( $_filterElement.find('.properties__item:visible').length === 0) {
                    $('.properties__no-results').show();
                } else {
                    $('.properties__no-results').hide();
                }
                
                $('.properties__group').each(function () {
                    if ( $(this).find('.properties__item:visible').length === 0 ) {
                        $(this).find('.properties__group-intro').hide();
                    } else {
                        $(this).find('.properties__group-intro').show();
                    }
                });
            }, 500); 

            
        });

        
    });
});

// Filter a list to only unique values
// https://stackoverflow.com/questions/12551635/jquery-remove-duplicates-from-an-array-of-strings
function unique(list) {
    var result = [];
    $.each(list, function(i, e) {
        if ($.inArray(e, result) == -1) result.push(e);
    });
    return result;
}
